.ardna-button {
  border: 1px solid $ardna-gold-lighter;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  background-color: $ardna-gold-lighter;
  font-size: 0.9em;
  font-family: AvenirBlack;
  letter-spacing: 0.06px;
  transition: all 0.8s linear;
  width: 110px;
  display: inline-block;
  max-width: 110px;
  position: relative;
  vertical-align: middle;
  display: inline-block;
  line-height: 60px;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;
  -webkit-transition: 0.5s;
  @media screen and (min-width: 375px) {
    width: 125px;
    max-width: 125px;
    line-height: 60px;
  }
  &:first-of-type {
    margin-right: 1em;
  }
  &:last-of-type {
    margin-left: 1em;
  }

  &::before,
  &::after {
    width: 100%;
    height: 100%;
    z-index: 3;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    -webkit-transform: scale(0);
    transition: 0.5s;
    width: 100%;
    height: 100%;
    z-index: 3;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    -webkit-transform: scale(0);
    transition: 0.5s;
  }
  &::before {
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform-origin: 0 100%;
  }
  &::after {
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    -webkit-transform-origin: 100% 0%;
  }
  &:hover {
    border: 1px solid rgba(0, 160, 80, 0);
    color: #fff;
    &::after,
    &::before {
      -webkit-transform: scale(1);
    }
  }

  &--ghost {
    border: 1px solid $ardna-gold-lighter;
    color: $ardna-gold-lighter;
    background-color: transparent;
  }
  &--ghost-dark {
    border: 1px solid $ardna-black;
    color: $ardna-black;
    background-color: transparent;
    padding: 20px 0;
    width: 100%;
    max-width: 415px;
    p {
      font-family: AvenirMedium;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 2.18px;
      &:hover {
        text-decoration: none !important;
      }
    }
    &:first-of-type {
      margin-right: auto;
    }
    &:last-of-type {
      margin-left: auto;
    }
    &::before {
      border-bottom: 1px solid $ardna-black;
      border-left: 1px solid $ardna-black;
    }
    &::after {
      border-top: 1px solid $ardna-black;
      border-right: 1px solid $ardna-black;
    }
    &:hover {
      border: 1px solid rgba(0, 160, 80, 0);
      color: $ardna-black;
    }
  }

  &--form {
    height: 60px;
    border: 1px solid $ardna-gold-lighter;
    &:first-of-type {
      margin-left: 0px;
    }
    &:last-of-type {
      margin-right: 0;
    }
    margin-right: 0;
  }
  p {
    margin-bottom: 0;
    line-height: 1.4;
  }
}
