#your-bottle {
  img.logo {
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 200px;
    @media screen and (min-width: 375px) {
      max-width: 270px;
    }
    @media screen and (min-width: 700px) {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }

  img.bottle {
    max-height: 250px;
    margin-bottom: 10px;
    @media screen and (min-width: 375px) {
      max-height: 350px;
    }
    @media screen and (min-width: 700px) {
      max-height: 500px;
    }
  }

  .heading {
    &--your-bottle {
      font-size: 20px;
      margin-bottom: 0;
      letter-spacing: 5px;
      text-align: center;
      color: $ardna-black;
    }
    &--bottle-id {
      margin-top: -10px;
      font-size: 40px;
      text-align: center;
      letter-spacing: 5px;
      color: $ardna-gold;
    }
    &--malt-id {
      text-transform: uppercase;
      font-size: 16px;
    }
  }

  .line-decoration {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    margin-bottom: 30px;
    width: 48px;
    border-bottom: 1px solid $ardna-black;
  }

  .bottlingInfo {
    text-align: left;
    &__icon {
      width: 30px;
      height: 30px;
      margin-top: -6px;
      @include media-breakpoint-up(sm) {
        width: 40px;
        height: 40px;
      }
    }

    &--gold {
      float: right;
      color: $ardna-gold;
      cursor: pointer;
      &--last {
        margin-bottom: 30px;
      }
    }
  }

  .bodycopy {
    text-align: center;
  }

  .sign-up {
    margin-top: 30px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    color: $ardna-gold;
    img {
      margin-bottom: 4px;
      margin-left: 5px;
    }
    &:hover {
      color: $ardna-gold-darker;
    }
    &::after {
      content: "";
      width: 0px;
      height: 1px;
      display: block;
      background: $ardna-gold-darker;
      transition: 300ms;
    }

    &:hover::after {
      width: 100%;
    }
  }
}
