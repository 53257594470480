#social-links {
  img.logo {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 200px;
    margin-bottom: 50px;
    @media screen and (min-width: 375px) {
      max-width: 270px;
    }
  }
  .play {
    margin-top: 20px;
    margin-bottom: 40px;
    img {
      margin-right: 6px;
      margin-top: -5px;
      height: 40px;
    }
    p {
      font-family: AvenirMedium;
      letter-spacing: 2px;
      display: inline-block;
      margin-bottom: 0px;
      text-transform: uppercase;
      font-size: 22px;
      color: $ardna-gold;
      font-weight: 600;
    }
  }
  .heading {
    font-family: AvenirMedium;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 18px;
    margin-bottom: 20px;
    span {
      display: block;
    }
  }
  .link-container {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    img {
      cursor: pointer;
    }
  }
  .mailing-header {
    margin-bottom: 0px;
    line-height: 1;
    font-weight: 300;
    font-family: EngraversRomanBold;
    color: #c0853b;
    font-size: 20px;
    letter-spacing: 5px;
    &--bold {
      color: #fff;
      font-size: 36px;
      display: block;
      @media screen and (min-width: 365px) {
        font-size: 46px;
      }
    }
  }
  .email-message {
    height: 41px;
    width: 100%;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    text-align: left;

    .alert-success,
    .alert-warning {
      padding: 6px 12px;
      margin-bottom: 6px;
      p {
        font-size: 14px;
      }
      &__message {
        margin-bottom: 0;
      }
    }

    .display-none {
      max-height: 0;
      transition: max-height 0.6s, opacity 0.2s, visibility 0.5s linear;
      visibility: hidden;
      opacity: 0;
    }
    .display {
      max-height: 250px;
      transition: max-height 0.8s, opacity 0.5s, visibility 0.5s linear;
      visibility: visible;
      opacity: 1;
    }

    .spinner {
      width: 31px;
      height: 31px;
      .keyframes {
        transform: translateZ(0) scale(0.31);
      }
      .keyframes div {
        width: 80px;
        height: 80px;
        top: 10px;
        left: 10px;
        border-radius: 50%;
        transform-origin: 40px 41px;
      }
    }
  }
  form {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 60px;
    @media screen and (min-width: 500px) {
      width: 400px;
    }
    .form-control {
      font-family: AvenirMedium;
      color: #000;
      border-radius: 0;
      font-size: 16px;
      height: 60px;
      border-style: none;
    }
  }

  .link {
    margin-left: -20px;
    display: inline-block;
    letter-spacing: 3px;
    font-size: 18px;
    color: $ardna-gold;
    text-decoration: none;
    img {
      margin-bottom: 4px;
      margin-left: 5px;
    }
    &:hover {
      color: $ardna-gold-darker;
    }
    &::after {
      content: "";
      width: 0px;
      height: 1px;
      display: block;
      background: $ardna-gold-darker;
      transition: 300ms;
    }

    &:hover::after {
      width: 100%;
    }
  }
}
