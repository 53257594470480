//Use bootstrap sparingly

// Core variables and mixins
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins";

// Headings and grid responsive
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/mixins/clearfix";
@import "~bootstrap/scss/mixins/grid-framework";
@import "~bootstrap/scss/mixins/image";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/alert";

@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/clearfix";

//import all
// @import "~bootstrap/scss/bootstrap";

img.img-responsive {
  max-width: 100%;
  height: auto;
}
