#bottle-not-found {
  background: #334655;
  .content {
    background: #334655;
    background-image: url("../../assets/bg-desktop@2x.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
    color: #fff;
    height: 100vh;
    padding: 20px;

    .container,
    .row {
      height: 100%;
    }
    .border {
      height: 100%;
      width: 100%;
      border: 1px rgba(197, 158, 88, 0.25) solid;
    }

    .header {
      margin-bottom: 1.3em;
      &--large {
        display: block;
        font-size: 1.5em;
        @media screen and (min-width: 375px) {
          font-size: 2em;
        }
      }
    }
    img {
      margin-top: 3em;
      margin-bottom: 3em;
      max-width: 200px;
      opacity: 1;
      @media screen and (min-width: 375px) {
        max-width: 270px;
      }
    }

    .tcs {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      color: #fff;
    }
    a {
      color: $ardna-gold;
      font-weight: 600;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
