#ageguard {
  background: #334655;
  .content {
    background: #334655;
    background-image: url("../../assets/bg-desktop@2x.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
    color: #fff;
    height: 100vh;
    padding: 20px;

    .container,
    .row {
      height: 100%;
    }
    .border {
      height: 100%;
      width: 100%;
      border: 1px rgba(197, 158, 88, 0.25) solid;
    }

    .flex-container {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      .flex-item-1 {
        flex-grow: 1;
      }
      .flex-item-2 {
        flex-grow: 2;
      }
    }

    .header {
      &--large {
        margin-bottom: 1.3em;
        font-size: 1.5em;
        @media screen and (min-width: 375px) {
          font-size: 1.8em;
        }
        @include media-breakpoint-up(sm) {
          font-size: 2em;
        }
      }
    }
    img {
      margin-top: 60px;
      max-width: 200px;
      margin-left: 20px;
      @media screen and (min-width: 375px) {
        max-width: 250px;
      }
      @include media-breakpoint-up(sm) {
        max-width: 270px;
      }
    }
    .button-wrapper {
      margin-top: 40px;
    }
    .tcs {
      text-align: center;
      font-size: 14px;
      @include media-breakpoint-up(sm) {
        font-size: 16px;
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}
