.landingpage {
  background: #fff;

  .section {
    padding-top: 100px;
    &--dark {
      background-color: #f6f7f8;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &--green {
      background-color: #3c4e39;
      color: #fff;
      margin-top: 100px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &--blue {
      background-color: #233c51;
      margin-top: 100px;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &--slim {
      padding-top: 40px;
    }

    &--img {
      background-color: #233c51;
      background-image: url("../../assets/bg-desktop@2x.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .mini-section {
    margin-top: -50px;
    margin-bottom: 50px;
    font-style: italic;
    font-family: AvenirRegular;
  }

  .header {
    font-size: 20px;
    letter-spacing: 5px;
    font-weight: 400;
    &--large {
      line-height: 0.8;
      text-transform: capitalize;
      letter-spacing: 0.5px;
      display: block;
      font-size: 28px;
      @media screen and (min-width: 400px) {
        font-size: 32px;
      }
    }
  }

  .video {
    margin-bottom: 30px;
  }

  .bodycopy {
    text-align: left;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.06px;
    color: #282826;
  }
  .line-decoration {
    margin-left: auto;
    margin-right: auto;
    width: 48px;
    border-bottom: 1px solid $ardna-black;
  }
}
