#true-traceability {
  .link-container {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    img {
      cursor: pointer;
    }
  }
  .header {
    margin-bottom: 20px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 300;
    font-family: EngraversRomanBold;
    color: $ardna-black;
    font-size: 20px;
    letter-spacing: 5px;
    &--bold {
      text-transform: capitalize;
      color: $ardna-gold;
      font-size: 36px;
      display: block;
      @media screen and (min-width: 365px) {
        font-size: 46px;
      }
    }
    &--thin {
      font-family: AvenirMedium;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 18px;
      margin-bottom: 30px;
      span {
        display: block;
      }
    }
  }
  .description {
    margin-top: 30px;
    text-align: left;
  }

  .timeline-wrapper {
    padding-left: 30px;
    border-left: solid 1px rgba(188, 194, 198, 0.3);
  }
  .display-none {
    display: none !important;
  }
}
