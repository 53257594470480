.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #334655;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  height: 100vh;
  padding: 20px;
  .border {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px rgba(197, 158, 88, 0.25) solid;
  }
}

@keyframes keyframes {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  .keyframes {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  .keyframes div {
    position: absolute;
    animation: keyframes 1s linear infinite;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    box-shadow: 0 4px 0 0 #cba96b;
    transform-origin: 80px 82px;
  }
  .keyframes.white div {
    box-shadow: 0 4px 0 0 #fff;
  }
  .keyframes div {
    box-sizing: content-box;
  }
}
