.event-wrapper {
  text-align: left;
  &::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: $ardna-gold;
    margin-left: -38px;
    margin-bottom: 3px;
  }

  #event,
  .event-card {
    margin-top: -24px;
    margin-bottom: 20px;
    border: solid 1px rgba(188, 194, 198, 0.3);
    color: #696b73;
    text-align: left;

    &__description {
      padding: 10px;
      p {
        margin-bottom: 0;
        letter-spacing: 0.06px;
        font-size: 16px;
        color: #696b73;
      }
      p.link {
        color: $ardna-gold;
        cursor: pointer;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
          color: $ardna-gold-darker;
        }
      }
    }
    a {
      color: $ardna-gold;
      &:hover {
        color: $ardna-gold-darker;
      }
    }
  }
}

.event-wrapper.display-none {
  max-height: 0;
  transition: max-height 0.6s, opacity 0.2s, visibility 0.5s linear;
  visibility: hidden;
  opacity: 0;
}
.event-wrapper.display {
  max-height: 600px;
  transition: max-height 0.8s, opacity 0.5s, visibility 0.5s linear;
  visibility: visible;
  opacity: 1;
}
