.bc-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 700px;
  max-width: 100%;
  word-wrap: break-word;
  transform: translate(-50%, -50%);
  z-index: 1003;
  background-color: #fbfbfb;
  border-radius: 12px;
  box-shadow: 0 4px 7px -2px rgba(192, 133, 59, 0.2);
  @include media-breakpoint-up(sm) {
  }
  .close {
    float: right;
    cursor: pointer;
  }
  .bc-modal-inner {
    border: solid 1px rgba(192, 133, 59, 0.5);
    border-radius: 4px;
    padding: 30px;

    p {
      text-align: left;
      font-family: AvenirMedium;
      font-size: 16px;
      line-height: 2.19;
      letter-spacing: 0.06px;
      color: #282826;
      margin-bottom: 0;
      span {
        font-family: AvenirRegular;
      }
    }
    .spinner {
      width: 200px;
      height: 200px;
      .keyframes {
        transform: translateZ(0) scale(1);
      }
      .keyframes div {
        width: 60px;
        height: 60px;
        top: 70px;
        left: 70px;
        border-radius: 50%;

        transform-origin: 30px 32px;
      }
    }
  }
}
.bc-modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 60, 81, 0.5);
}
