#tasting-notes {
  .heading {
    &--primary {
      margin-top: -10px;
      font-size: 30px;
      text-align: center;
      letter-spacing: 5px;
      color: $ardna-gold;
    }
    &--secondary {
      font-family: AvenirMedium;
      line-height: 1.88;
      text-transform: uppercase;
      letter-spacing: 4.2px;
      font-size: 14px;
    }

    span {
      display: block;
    }
    @include media-breakpoint-up(sm) {
      &--primary {
        font-size: 46px;
      }
      &--secondary {
        font-size: 16px;
      }
    }
  }
  .bold {
    font-weight: bold;
    font-family: 'AvenirBlack';
  }

  .line-decoration {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    margin-bottom: 30px;
    width: 48px;
    border-bottom: 1px solid $ardna-black;
  }
}
