@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"), url(../../fonts/Avenir-Black.woff2) format("woff2");
}
@font-face {
  font-family: "AvenirMedium";
  src: local("AvenirMedium"),
    url(../../fonts/Avenir-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "AvenirRegular";
  src: local("AvenirRegular"),
    url(../../fonts/AvenirNext-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "EngraversRomanBold";
  src: local("EngraversRomanBold"),
    url(../../fonts/EngraversRomanBT-Bold.ttf) format("truetype");
}

p {
  font-family: AvenirRegular;
  font-size: 16px;
}
h1,
h2 {
  font-family: EngraversRomanBold;
  text-transform: uppercase;
  font-size: 16px;
}

h3 {
  font-family: AvenirBlack;
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: 4.2px;
  text-align: center;
  color: $ardna-black;
}

h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.06px;
  font-family: AvenirMedium;
  color: $ardna-black;
}

.h5 {
  text-transform: uppercase;
}

h6.event-heading {
  font-family: EngraversRomanBold;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.06px;
  color: #282826;
}

a {
  font-family: AvenirRegular;
}
